import { FC, useRef, useCallback, useState, useEffect, useMemo, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { EWebcamMode, SM } from 'const/ui.constant';
import { ICaptureImage } from 'models/common.model';
import { setCaptureImage } from 'store/slices/recognition.slice';
import { addCaptureImage, increaseActiveStep } from 'store/slices/register.slice';
import { RootState } from 'store/store';

interface IWebcamCapture {
  isShowCamera: boolean;
  setIsCompleteStep?: Dispatch<React.SetStateAction<boolean>>;
  webCamMode: EWebcamMode;
  statusImgs: string[];
}

interface IVideoConstraints {
  width: number;
  height: number;
  facingMode: string;
}

const WebcamCapture: FC<IWebcamCapture> = ({ isShowCamera, webCamMode, statusImgs, setIsCompleteStep }) => {
  const webcamRef = useRef(null);
  const [captureImageStep, setCaptureImageStep] = useState<number>(0);
  const captureImages = useSelector((state: RootState) => state.register.captureImages);

  const dispatch = useDispatch();

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const newCaptureImage: ICaptureImage = {
      image: imageSrc,
      status:
        webCamMode === EWebcamMode.RECOGNITION
          ? 'recognition'
          : statusImgs[captureImageStep].replace('Please take a ', '')
    };

    if (webCamMode === EWebcamMode.RECOGNITION) {
      dispatch(setCaptureImage(newCaptureImage));
      return;
    }

    setCaptureImageStep(prev => prev + 1);
    dispatch(addCaptureImage(newCaptureImage));
  }, [webcamRef, captureImageStep, dispatch]);

  useEffect(() => {
    if (webCamMode === EWebcamMode.RECOGNITION) return;

    setIsCompleteStep(captureImages.length === statusImgs.length);
    if (captureImages.length === statusImgs.length) {
      dispatch(increaseActiveStep());
    }
  }, [captureImages, setIsCompleteStep]);

  useEffect(() => {
    if (webCamMode === EWebcamMode.RECOGNITION) return;
    setIsCompleteStep(false);
  }, []);

  const videoConstraints = useMemo(() => {
    const vd: IVideoConstraints = {
      width: window.innerWidth > SM ? window.innerWidth * 0.5 : window.innerWidth,
      height: window.innerWidth > SM ? window.innerWidth * 0.5 * 0.6 : window.innerHeight * 0.6,
      facingMode: 'user'
    };
    return vd;
  }, [window.innerWidth]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isShowCamera === false) {
      setIsLoading(true);
    }
  }, [isShowCamera]);

  const handleUserMedia = () => {
    setIsLoading(false);
  };
  return (
    <Box className="flex justify-center items-center">
      {isLoading === true && isShowCamera === true && (
        <Box
          className="flex justify-center items-center 
                  absolute rounded-md bg-gray-800"
          sx={{ width: `${videoConstraints.width - 32}px`, height: `${videoConstraints.height}px` }}
        >
          <div className="absolute top-50 left-50">
            <CircularProgress size="40px" />
          </div>
        </Box>
      )}
      <Stack gap="12px" className="justify-center items-center">
        {!isLoading === true && isShowCamera === true && (
          <Typography variant="h2">{statusImgs[captureImageStep]}</Typography>
        )}

        {isShowCamera && (
          <Box className="relative">
            <Box sx={{ transform: 'scaleX(-1)' }}>
              <Webcam
                ref={webcamRef}
                className="rounded-md"
                id="webcam"
                audio={false}
                screenshotFormat="image/jpeg"
                width={videoConstraints.width - 32}
                height={videoConstraints.height}
                videoConstraints={videoConstraints}
                onUserMedia={handleUserMedia}
              />
            </Box>

            {!isLoading && (
              <Box
                className="flex justify-center items-center hover:opacity-50 absolute bottom-0 left-0 mb-2"
                sx={{ width: `${videoConstraints.width - 32}px` }}
              >
                <button
                  className="w-16 h-16 rounded-full bg-transparent border-2 border-white text-xl text-white 
                flex justify-center items-center hover:cursor-pointer"
                  type="button"
                  onClick={handleCapture}
                >
                  <PhotoCameraIcon />
                </button>
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default WebcamCapture;
