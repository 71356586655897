import React from 'react';
import { useRoutes } from 'react-router-dom';
import NotFound from 'pages/NotFound.page';
import MAIN_ROUTES from './main.route';

const NOT_FOUND_ROUTE = {
  path: '*',
  element: <NotFound />
};

const AppRoutes = () => useRoutes([MAIN_ROUTES, NOT_FOUND_ROUTE]);

export default AppRoutes;
