import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoopIcon from '@mui/icons-material/Loop';
import SouthIcon from '@mui/icons-material/South';
import { Box, Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { COLORS, EWebcamMode } from 'const/ui.constant';
import useUI from 'hooks/ui.hook';
import { resetRecognitionSlice } from 'store/slices/recognition.slice';
import { RootState } from 'store/store';

const RecognitionResult = () => {
  const { captureImage, recognizeResult, isLoading } = useSelector((state: RootState) => state.recognition);
  const { backHomePage } = useUI(EWebcamMode.RECOGNITION);

  const dispatch = useDispatch();

  const handleRecognitionAgain = () => {
    dispatch(resetRecognitionSlice());
  };

  return (
    <Box className="w-full">
      <Stack className="m-4 gap-4 justify-center items-center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {' '}
            <Box className="w-full flex justify-between items-center">
              <Typography variant="h2">Recognition Result</Typography>
              <button
                className="text-3xl font-bold color text-primary flex items-center"
                type="button"
                onClick={handleRecognitionAgain}
              >
                <LoopIcon fontSize="inherit" />
              </button>
            </Box>
            <Paper className="w-full">
              <Stack className="justify-center items-center p-4 w-full gap-4">
                <Box sx={{ transform: 'scaleX(-1)' }}>
                  <img className="w-52" src={captureImage.image} alt={captureImage.status} />
                </Box>
                <Box className="text-xl font-bold">
                  <SouthIcon />
                </Box>
                <Typography sx={{ color: COLORS.primary }} variant="h2" className="font-bold">
                  {recognizeResult}
                </Typography>
              </Stack>
            </Paper>
            <Button fullWidth sx={{ height: '48px', fontSize: '16px' }} variant="contained" onClick={backHomePage}>
              Back to home page
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default RecognitionResult;
