import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Stack } from '@mui/material';
import { EWebcamMode, URLS } from 'const/ui.constant';
import RecognitionResult from 'components/RecognitionResult.component';
import WebcamCapture from 'components/WebcamCapture.component';
import { ApiHelper } from 'helpers/api.helper';
import { convertToBase64 } from 'helpers/image.helper';
import { resetRecognitionSlice, setIsLoading, setRecognizeResult } from 'store/slices/recognition.slice';
import { RootState } from 'store/store';
import 'react-toastify/dist/ReactToastify.css';

const statusImgs = ['Please take a photo to recognize'];

const RecognitionPage = () => {
  const { captureImage } = useSelector((state: RootState) => state.recognition);

  const notify = content =>
    toast.error(content, {
      position: toast.POSITION.TOP_LEFT
    });
  const dispatch = useDispatch();

  useEffect(() => {
    if (captureImage) {
      dispatch(setIsLoading(true));
      ApiHelper.post(`${URLS.predict}`, {
        data: {
          image: convertToBase64(captureImage.image)
        }
      })
        .then(res => {
          if (res.data.detail) {
            dispatch(resetRecognitionSlice());
            notify('Cannot detect face');
            return;
          }
          dispatch(setRecognizeResult(res.data.id));
        })
        .catch(() => {
          notify('Image is invalid');
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [captureImage]);
  return (
    <Stack className="w-full h-full justify-center items-center">
      {captureImage ? (
        <RecognitionResult />
      ) : (
        <WebcamCapture isShowCamera statusImgs={statusImgs} webCamMode={EWebcamMode.RECOGNITION} />
      )}
      <ToastContainer />
    </Stack>
  );
};

export default RecognitionPage;
