import React, { FC } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import checked from 'assets/img/checked.png';
import { EWebcamMode } from 'const/ui.constant';
import useUI from 'hooks/ui.hook';

const Success: FC = () => {
  const { backHomePage } = useUI(EWebcamMode.REGISTER);

  return (
    <Stack className="flex justify-center items-center p-4" gap="12px">
      <img className="w-36 h-36 sm:w-20 sm:h-20" src={checked} alt="checked" />
      <Typography variant="h2">Enroll user success</Typography>
      <Button fullWidth sx={{ height: '48px', fontSize: '16px' }} variant="contained" onClick={backHomePage}>
        Back to home page
      </Button>
    </Stack>
  );
};

export default Success;
