import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICaptureImage } from 'models/common.model';

interface IRecognitionSliceState {
  captureImage?: ICaptureImage;
  isLoading?: boolean;
  recognizeResult: string;
}

export const initialState: IRecognitionSliceState = {
  recognizeResult: ''
};

const recognitionSlice = createSlice({
  name: 'recognition',
  initialState,
  reducers: {
    setCaptureImage: (state: IRecognitionSliceState, action: PayloadAction<ICaptureImage>) => ({
      ...state,
      captureImage: action.payload
    }),
    resetRecognitionSlice: () => initialState,
    setIsLoading: (state: IRecognitionSliceState, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    }),
    setRecognizeResult: (state: IRecognitionSliceState, action: PayloadAction<string>) => ({
      ...state,
      recognizeResult: action.payload
    })
  }
});

const { actions, reducer } = recognitionSlice;
export const { setCaptureImage, resetRecognitionSlice, setIsLoading, setRecognizeResult } = actions;
export default reducer;
