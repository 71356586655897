import { Outlet } from 'react-router-dom';
import { ROUTE_PATHS } from 'const/ui.constant';
import Layout from 'layout/Layout.component';
import { HomePage, RecognitionPage, RegisterPage } from 'pages';

const MAIN_ROUTES = {
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '',
      element: <Outlet />,
      children: [
        {
          path: ROUTE_PATHS.home,
          element: <HomePage />
        },
        {
          path: ROUTE_PATHS.register,
          element: <RegisterPage />
        },
        {
          path: ROUTE_PATHS.recognition,
          element: <RecognitionPage />
        }
      ]
    }
  ]
};

export default MAIN_ROUTES;
