import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import bgSvg from 'assets/bg/plus-bg.svg';
import notFoundSvg from 'assets/img/not-found.svg';
import { PAGE_CONTAINER_STYLES, ROUTE_PATHS } from 'const/ui.constant';

const NotFound = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: { xs: 'none', sm: `url(${bgSvg})` },
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      }}
    >
      <Box
        sx={{
          ...PAGE_CONTAINER_STYLES,
          textAlign: 'center'
        }}
      >
        <img src={notFoundSvg} alt="Coming soon" />
        <Typography variant="h1" color="primary" mb="4px">
          Page Not Found
        </Typography>
        <Typography mb="30px">Oops. Looks like this page doesn&rsquo;t exist.</Typography>
        <Button variant="contained" onClick={() => navigate(ROUTE_PATHS.home)}>
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
