import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EWebcamMode, ROUTE_PATHS } from 'const/ui.constant';
import { resetRecognitionSlice } from 'store/slices/recognition.slice';
import { resetRegisterSlice } from 'store/slices/register.slice';

export type THandler = (...args: unknown[]) => void;

interface IUIHook {
  backHomePage: THandler;
}

const useUI = (webcamMode: EWebcamMode): IUIHook => {
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const backHomePage = () => {
    navigator(ROUTE_PATHS.home);
    if (webcamMode === EWebcamMode.REGISTER) {
      dispatch(resetRegisterSlice());
      return;
    }
    dispatch(resetRecognitionSlice());
  };

  return {
    backHomePage
  };
};

export default useUI;
