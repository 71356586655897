import { configureStore } from '@reduxjs/toolkit';
import recognitionReducer from './slices/recognition.slice';
import registerReducer from './slices/register.slice';

export const store = configureStore({
  reducer: {
    register: registerReducer,
    recognition: recognitionReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
