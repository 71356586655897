import React, { Dispatch, FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { setUsername } from 'store/slices/register.slice';
import { RootState } from 'store/store';

export interface IInputForm {
  setIsCompleteStep: Dispatch<React.SetStateAction<boolean>>;
}

const InputForm: FC<IInputForm> = ({ setIsCompleteStep }) => {
  const username = useSelector((state: RootState) => state.register.username);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setIsCompleteStep(false);
  }, []);
  const dispatch = useDispatch();
  const handleChange = event => {
    dispatch(setUsername(event.target.value));
    setError(event.target.value === '');
    setIsCompleteStep(event.target.value !== '');
  };
  return (
    <Stack gap="8px" className="justify-center px-4">
      <Typography variant="h2">Username</Typography>
      <TextField
        fullWidth
        autoFocus
        required
        type="text"
        placeholder="Input Username"
        error={error}
        helperText={error ? 'Empty field!' : ''}
        value={username}
        size="medium"
        inputProps={{ style: { fontSize: 16 } }}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default InputForm;
