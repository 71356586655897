import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { RootState } from 'store/store';

const VerifySubmit: FC = () => {
  const captureImages = useSelector((state: RootState) => state.register.captureImages);
  const username = useSelector((state: RootState) => state.register.username);
  return (
    <Paper className="m-4">
      <Stack className="justify-center p-4" gap="12px" divider={<Divider />}>
        <Typography variant="h2">Username: {username}</Typography>
        {captureImages.map(captureImage => (
          <Box key={captureImage.status} className="flex gap-4 justify-start items-center">
            <Box sx={{ transform: 'scaleX(-1)' }}>
              <img className="w-28" src={captureImage.image} alt={captureImage.status} />
            </Box>
            <Typography>{captureImage.status}</Typography>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default VerifySubmit;
