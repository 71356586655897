import { createTheme } from '@mui/material/styles';
import { COLORS } from 'const/ui.constant';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.5
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderColor: 'inherit',
          textTransform: 'none'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#8A8B9F'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#E7EDF5',
          fontSize: '12px',
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            marginRight: '8px'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            padding: '6px'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            padding: '4px 8px',
            '& .MuiMenu-list': {
              padding: '4px 0'
            },
            '& .MuiMenuItem-root': {
              borderRadius: '4px',
              padding: '6px 8px',
              fontSize: '14px',
              '& .MuiListItemIcon-root': {
                minWidth: '30px'
              },
              '&:hover': {
                color: COLORS.primary,
                backgroundColor: COLORS.background[100],
                '& path': {
                  stroke: COLORS.primary
                }
              },
              '&.Mui-selected': {
                color: COLORS.primary,
                backgroundColor: COLORS.background[100]
              }
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '&& fieldset': {
              borderColor: COLORS.primary
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important'
          },
          '&.Mui-error': {
            '& fieldset': {
              borderColor: `${COLORS.red[400]} !important`
            }
          },
          '&.Mui-disabled': {
            backgroundColor: '#8A8B9F20',
            '& .MuiInputBase-input': {
              cursor: 'not-allowed'
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
              color: '#9E9E9E'
            },
            '& fieldset': {
              borderColor: '#BDBDBD !important'
            }
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            border: '1px solid #E5E5E5',
            boxShadow:
              '-2px 8px 12px rgb(0 0 0 / 5%), 0px 12.16px 8.576px rgb(0 0 0 / 1%), 0px 2.72px 3.712px rgb(0 0 0 / 1%)'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: COLORS.primary
    },
    error: {
      main: COLORS.red[400]
    },
    warning: {
      main: COLORS.orange[400]
    },
    success: {
      main: COLORS.green[400]
    }
  },
  typography: {
    fontFamily: ['Inter', 'Arial'].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    allVariants: {
      color: COLORS.text
    },
    h1: {
      fontSize: '22px',
      fontWeight: 600
    },
    h2: {
      fontSize: '18px',
      fontWeight: 600
    },
    h3: {
      fontSize: '16px',
      fontWeight: 500
    },
    h4: {
      fontSize: '14px',
      fontWeight: 500
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '12px'
    }
  }
});

export default theme;
