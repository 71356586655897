import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import faceMask from 'assets/img/face-mask.svg';
import { ROUTE_PATHS } from 'const/ui.constant';

const HomePage = () => {
  const navigate = useNavigate();

  const handleEnroll = () => {
    navigate(ROUTE_PATHS.register);
  };

  const handleScan = () => {
    navigate(ROUTE_PATHS.recognition);
  };
  return (
    <div className="flex flex-col w-full h-full items-center justify-center gap-4 relative">
      <img className="w-36 h-36 sm:w-20 sm:h-20" alt="Face mask icon" src={faceMask} />
      <Typography variant="h1">Masked Face Recognition</Typography>
      <Typography>Scan your face to verify your identity</Typography>

      <Stack className="w-full absolute left-0 bottom-10 px-4" gap="12px">
        <Button fullWidth sx={{ height: '48px', fontSize: '16px' }} variant="contained" onClick={handleScan}>
          Scan
        </Button>
        <Button fullWidth sx={{ height: '48px', fontSize: '16px' }} variant="outlined" onClick={handleEnroll}>
          Enroll
        </Button>
      </Stack>
    </div>
  );
};

export default HomePage;
