import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { ERegisterStep, EWebcamMode, ROUTE_PATHS, URLS } from 'const/ui.constant';
import InputForm from 'components/InputForm.component';
import Success from 'components/Success.component';
import VerifySubmit from 'components/VerifySubmit.component';
import WebcamCapture from 'components/WebcamCapture.component';
import { ApiHelper } from 'helpers/api.helper';
import { convertToBase64 } from 'helpers/image.helper';
import { decreaseActiveStep, increaseActiveStep, resetRegisterSlice, setIsLoading } from 'store/slices/register.slice';
import { RootState } from 'store/store';
import 'react-toastify/dist/ReactToastify.css';

const steps = ['Input Username', 'Add Images', 'Submit'];
const statusImgs = [
  'Please take a photo of the front view',
  'Please take a photo of left view',
  'Please take a photo of right view'
];

const RegisterPage = () => {
  const isShowCamera = useMemo(() => true, []);
  const [isCompleteStep, setIsCompleteStep] = useState<boolean>(true);
  const { captureImages, activeStep, username, isLoading } = useSelector((state: RootState) => state.register);
  const navigator = useNavigate();

  const renderComponent = () => {
    switch (activeStep) {
      case ERegisterStep.INPUT_USER_NAME:
        return <InputForm setIsCompleteStep={setIsCompleteStep} />;
      case ERegisterStep.CAPTURE_IMAGE:
        return (
          <WebcamCapture
            statusImgs={statusImgs}
            setIsCompleteStep={setIsCompleteStep}
            isShowCamera={isShowCamera}
            webCamMode={EWebcamMode.REGISTER}
          />
        );
      case ERegisterStep.SUBMIT:
        return <VerifySubmit />;
      default:
        return <Success />;
    }
  };

  const dispatch = useDispatch();

  const notify = content =>
    toast.error(content, {
      position: toast.POSITION.TOP_LEFT
    });

  const handleContinueStep = async () => {
    let isError = false;
    if (activeStep === ERegisterStep.SUBMIT) {
      dispatch(setIsLoading(true));
      const faces = {
        name: username,
        images: captureImages.map(captureImage => convertToBase64(captureImage.image))
      };
      await ApiHelper.post(`${URLS.faces}`, {
        data: {
          ...faces
        }
      })
        .catch(error => {
          isError = true;
          switch (error.response.status) {
            case 400:
              notify('Image is invalid');
              break;
            case 409:
              notify(error.response.data.detail);
              break;
            default:
              notify('Enroll error');
          }
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }

    if (isError) return;
    dispatch(increaseActiveStep());
  };

  const handleBackStep = () => {
    if (activeStep === ERegisterStep.INPUT_USER_NAME) {
      navigator(ROUTE_PATHS.home);
      dispatch(resetRegisterSlice());
      return;
    }
    dispatch(decreaseActiveStep());
  };
  return (
    <div className="flex flex-col w-full h-full justify-center relative">
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <CircularProgress />
        </div>
      ) : (
        renderComponent()
      )}
      <Box className="w-full absolute left-0 top-10 mx-auto">
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep !== ERegisterStep.SUCCESS && (
        <Box className="flex justify-between absolute left-0 bottom-10 w-full px-4">
          <Button variant="outlined" size="large" onClick={handleBackStep}>
            Back
          </Button>
          <Button variant="contained" size="large" disabled={!isCompleteStep} onClick={handleContinueStep}>
            {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
          </Button>
        </Box>
      )}
      <ToastContainer />
    </div>
  );
};

export default RegisterPage;
