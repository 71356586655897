import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERegisterStep } from 'const/ui.constant';
import { ICaptureImage } from 'models/common.model';

interface IRegisterSliceState {
  activeStep: number;
  captureImages: ICaptureImage[];
  username: string;
  isLoading: boolean;
}

export const initialState: IRegisterSliceState = {
  activeStep: 0,
  captureImages: [],
  username: '',
  isLoading: false
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    increaseActiveStep: (state: IRegisterSliceState) => ({
      ...state,
      activeStep: state.activeStep + 1
    }),
    decreaseActiveStep: (state: IRegisterSliceState, action: PayloadAction<number>) => {
      if (action.payload === ERegisterStep.CAPTURE_IMAGE) {
        return {
          ...state,
          username: '',
          activeStep: state.activeStep - 1
        };
      }
      return {
        ...state,
        captureImages: [],
        activeStep: state.activeStep - 1
      };
    },
    addCaptureImage: (state: IRegisterSliceState, action: PayloadAction<ICaptureImage>) => ({
      ...state,
      captureImages: [...state.captureImages, action.payload]
    }),
    setUsername: (state: IRegisterSliceState, action: PayloadAction<string>) => ({
      ...state,
      username: action.payload
    }),
    setIsLoading: (state: IRegisterSliceState, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    }),
    resetRegisterSlice: () => initialState
  }
});

const { actions, reducer } = registerSlice;
export const {
  increaseActiveStep,
  decreaseActiveStep,
  addCaptureImage,
  setUsername,
  setIsLoading,
  resetRegisterSlice
} = actions;
export default reducer;
