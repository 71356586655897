export const COLORS = {
  border: '#E0E0E0',
  highlightedText: '#D6D4F7',
  tableHeader: '#7B81A6',
  text: '#11263C',
  textButton: '#7B81A6',
  label: '#7B81A6',
  primary: '#4B4AB3',
  primaryShades: {
    400: '#4F46BA',
    300: '#918CDC',
    200: '#D6D4F7',
    100: '#F4F2FF'
  },
  secondary: {
    400: '#2F80ED',
    300: '#97BFF6',
    200: '#D5E6FB',
    100: '#EAF2FD'
  },
  background: {
    300: '#7B81A6',
    200: '#E7EDF5',
    100: '#F7F9FD'
  },
  grey: {
    600: '#1B2533',
    500: '#676878',
    400: '#8A8B9F',
    300: '#C5C5CF',
    200: '#E8E8EC',
    100: '#F9F9FA'
  },
  green: {
    400: '#66BB6A',
    300: '#B3DDB4',
    200: '#B9EEDA',
    100: '#F0F8F0'
  },
  orange: {
    400: '#FFA726',
    300: '#FFD392',
    200: '#FFEDD4',
    100: '#FFF6E9'
  },
  red: {
    400: '#D32F2F',
    300: '#FAA19A',
    200: '#FCD0CD',
    100: '#FEECEB'
  }
};

export const PAGE_CONTAINER_STYLES = {
  maxWidth: '1600px',
  margin: 'auto',
  px: { xs: '14px', md: '32px' }
};

export const PADDING_Y = 40;

export const SM = 640;

// Breakpoint of large screen when open navbar
export const QUICKLINK_MENU_DISPLAY_BREAKPOINT = 1268;

export const SIZES = {
  navigationWidth: 260
};

export const THIN_BORDER = `1px solid ${COLORS.border}`;

export const ROUTE_PATHS = {
  home: '/',
  register: '/register',
  recognition: '/recognition'
};

export const URLS = {
  faces: 'api/v1/faces',
  predict: 'api/v1/face/predict'
};

export enum ERegisterStep {
  INPUT_USER_NAME = 0,
  CAPTURE_IMAGE = 1,
  SUBMIT = 2,
  SUCCESS = 3
}

export enum EWebcamMode {
  REGISTER = 0,
  RECOGNITION = 1
}
