import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Theme } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { COLORS } from 'const/ui.constant';

interface IMainProps {
  theme: Theme;
}

const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'navigationShown'
})<IMainProps>(() => ({
  width: '100%',
  backgroundColor: COLORS.background[100]
}));

const Layout: FC = () => {
  const theme = useTheme();

  return (
    <Main theme={theme} sx={{ height: window.innerHeight }}>
      <Box
        sx={{
          maxWidth: '900px',
          marginX: 'auto',
          height: '100%',
          width: '100%'
        }}
      >
        <Outlet />
      </Box>
      <CssBaseline />
    </Main>
  );
};

export default Layout;
