import axios, { AxiosPromise, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

const apiTimeOut = 300000;
const baseUrl = process.env.REACT_APP_BASE_URL || 'https://1982-125-235-239-49.ap.ngrok.io/';

const appendCacheParam = (url: string): string => {
  const timestamp = new Date().getTime();
  const hasParams = url.includes('?');
  if (hasParams) {
    const params = url.substr(url.indexOf('?'));
    return params.length > 1 ? `${url}&t=${timestamp}` : `${url}t=${timestamp}`;
  }
  return `${url}?t=${timestamp}`;
};

type TFetchOption<T> = Partial<{
  data: T;
  headers: AxiosRequestHeaders;
  ignoreToken: boolean;
  timeout: number;
  method: string;
  forceSkipCache: boolean;
  withCredentials: boolean;
}>;

const fetch = <T>(
  url: string,
  { data, headers, timeout, method, forceSkipCache = true, withCredentials = false }: TFetchOption<T>
): AxiosPromise => {
  const config: AxiosRequestConfig = {
    headers: {
      ...headers
    },
    timeout: timeout || apiTimeOut,
    method,
    withCredentials
  };

  if (data) {
    config.data = data;
  }

  // Prevent caching GET request by making random parameter
  let newURL = `${baseUrl}${url}`;
  if (forceSkipCache) {
    newURL = method === HTTP_METHODS.GET ? appendCacheParam(newURL) : newURL;
  }

  return axios(newURL, config);
};

export { appendCacheParam };
export const ApiHelper = {
  get: <T>(url: string, options = {}): AxiosPromise => fetch<T>(url, { ...options, method: HTTP_METHODS.GET }),
  post: <T>(url: string, options = {}): AxiosPromise => fetch<T>(url, { ...options, method: HTTP_METHODS.POST }),
  put: <T>(url: string, options = {}): AxiosPromise => fetch<T>(url, { ...options, method: HTTP_METHODS.PUT }),
  patch: <T>(url: string, options = {}): AxiosPromise => fetch<T>(url, { ...options, method: HTTP_METHODS.PATCH }),
  delete: <T>(url: string, options = {}): AxiosPromise => fetch<T>(url, { ...options, method: HTTP_METHODS.DELETE })
};
